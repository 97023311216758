import { createApp } from 'vue'
import router from "@/router/router";
import App from './App.vue'
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import alertify from 'alertifyjs';
import 'jquery/src/jquery.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './assets/css/style.css'
import {getUrl} from "@/helpers/helpers";
require('@/assets/js/blockUI.js')


const app = createApp(App)

app.use(router)
app.config.globalProperties.alertify = alertify
/*app.config.globalProperties.apiURL = 'http://localhost/cotizadoremporio/'*/
app.config.globalProperties.medio = getUrl()
app.config.globalProperties.apiURL = 'https://api.emporiosport.com.mx/'
router.beforeEach((to, from, next) => {
    // Define un título personalizado en la meta de la ruta
    const pageTitle = to.meta.title || 'Título predeterminado'
    document.title = pageTitle

    // Continúa con la navegación
    next()
})


app.mount('#app')